html, body {
  font-family: 'Roboto', sans-serif;
}

.bad {
  color: red;
}

.good {
  color: green;
}

.stats-grid {
  background: #fff;
  color: #223843;
  padding: 5px 0;

  .grid-fluid {
    width: 100%;

    &:last-of-type {
      margin-top: 5px;
      padding-top: 5px;
      border-top: 1px solid #ccc;
    }
  }

  .col {
    text-align: center;
    border-right: 1px solid #ccc;
    // padding: 0 30px;

    &.col-small-2 {
      // padding: 0 15px;
    }

    &:last-child {
      border-right: 0;
    }

    .title {
      font-size: 12px;
      text-transform: uppercase;
    }

    .value {
      font-weight: bold;
      font-size: 16px;
      
    }
  }
}

.login-page {
  .hero-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: url('../images/tree-bg.jpg');
    background-size: cover;
  }

  input {
    opacity: .7;
  }

  .form {
    position: relative;
    z-index: 100;
  }

  .login-overlay {
    position: absolute;
    background: #fff;
    opacity: .4;
    z-index: -1;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
}

.full-page-graph {
  height: calc(100vh - 50px);

  &.bg-overview {
    background: #F6F4F0;
    // background: $blue-color;
  }

  &.bg-daily {
    background: #F6F4F0;
  }

  &.bg-settings {
    background: $blue-color;

    label {
      color: #fff;
    }

    input {
      background: $blue-color;
      border: 0;
      border-bottom: 1px solid #fff;
      padding: 0;
      color: #fff;
      font-weight: bold;
      height: auto;
      font-size: 30px;

      &:focus {
        border-bottom: 1px solid #fff;
        outline: none;
      }
    }

    button {
      width: 100%;
      border: #fff;
      color: $blue-color;
    }
  }
  
  .main-container {
    width: 100%;
    height: calc(100% - 50px);
  }

  .menu {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff;
    line-height: 50px;
    text-align: center;
    border-top: 1px solid #f0f0f0;

    .menu-item {
      position: relative;
      text-transform: uppercase;
      font-size: 14px;
      cursor: pointer;
      color: #999;

      &.active {
        font-weight: bold;
        color: #000;
      }
    }
  }

  .page-header {
    // margin-bottom: 15px;
    padding: 30px 0;

    .navigation {
      margin-bottom: 15px; 
      margin-left: 15px;

      .item {
        padding: 5px 10px;
        margin-right: 5px;
        font-size: 18px;

        &.disabled {
          color: #ccc;
        }
      }
      
    }

    .title {
      padding: 0 30px;
      font-weight: bold;
      font-size: 24px;
      text-transform: uppercase;
    }
  }


    
  .card-title {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    color: #555;
  }

  .weight-card {
    margin-bottom: 15px;
    color: #fff;

    .card-title {
      color: #f0f0f0;
    }

    .bg {
      background: $purple-color;
      padding: 15px 30px;
    }

    .value {
      font-weight: bold;
      font-size: 40px;
    }

    .material-icons {
      // background: red;
      font-size: 30px;
      padding: 0 15px;
      margin-top: 30px;
      margin-right: 5px;
      // color: $green-color;
    }
  }

  .stats {
    padding: 0 30px 45px;

    .stat-title {
      text-transform: uppercase;
      font-size: 12px;
    }

    .stat {
      margin-top: 30px;
      font-size: 30px;
    }

    .value {
      font-size: 30px;
    }
  }

  .toggle-card {
    .card-title {
      margin-bottom: 10px;
      margin-right: 30px;
    }

    .toggle-container {
      position: relative;
      background: #fff;
      margin-right: 30px;
      color: #999;
      padding: 15px;
      font-size: 40px;
      text-align: center;

      &.state-true {
        background: $green-color;
        color: #fff;
      }

      @for $i from 1 through 10 {
        &.state-#{$i} {
          background-color: darken(#FF8BA2, $i * 5%);
          color: #fff;
        }
      }

      .value {
        position: absolute;
        font-size: 10px;
        font-weight: bold;
        text-align: right;
        width: 100%;
        right: 20px;
        top: -34px;
        color: #333;
      }

      &.exercise {
        .value {
          display: none;
        }
      }
    }
  }
}

.chart-container {
  position: relative;


  .page-header {
    font-size: 18px;
    padding: 15px 30px 0;
    text-transform: uppercase;
    background: $green-color;
    text-align: center;
    color: #fff;
  }

  .chart {
    height: 250px;
    width: 100%;
  }

  .pie-wrap {
    position: relative;
    background: $green-color;
    padding: 15px 0;

    .percentage {
      position: absolute;
      top: 33%;
      text-align: center;
      width: 100%;
      opacity: .7;

      .pre, .post {
        font-size: 12px;
        color: $green-color;
        text-transform: uppercase;
        font-weight: bold;
      }

      .value {
        font-weight: bold;
        font-size: 30px;
        color: #555;
      }
    }
  }

  .trend-graph {
    padding: 15px 0;
  }

  .legend {
    font-size: 12px;
    padding-left: 30px;
    margin: 30px 0 30px;
    // text-align: right;

    .legend-item  {
      padding-right: 15px;
      text-transform: uppercase;

      &:before {
        position: relative;
        content: '';
        display: inline-block;
        height: 10px;
        width: 10px;
        background: #41b3a3;
        border-radius: 50%;
        margin-right: 5px;
      }

      &.goal {
        &:before {
          background: #ddd;
        }
      }

      &.projected {
        &:before {
          background: #e27d60;
        }
      }
    }
  }
}